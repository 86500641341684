.you-can-book-me-container {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

@media(max-width: 1200px) {
  .you-can-book-me-container {
    width: 1147px;
    height: auto;
    min-width: 100%;
    min-height: 800px;
  }
  .you-can-book-me-root-class-name {
    height: auto;
  }
}
