.book-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.book-image {
  width: 200px;
  object-fit: cover;
}
.book-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.book-hero {
  position: relative;
  padding-top: 20px;
}
.book-max-width {
  margin-top: var(--dl-space-space-threeunits);
}
.book-heading-container {
  flex: 1;
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.book-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.book-primary {
  margin-bottom: var(--dl-space-space-threeunits);
}
.book-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.book-top-part {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.book-links-container {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.book-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.book-text01 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.book-text02 {
  margin-bottom: var(--dl-space-space-unit);
}
.book-text03 {
  margin-bottom: var(--dl-space-space-unit);
}
.book-navigate-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.book-text05 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.book-text06 {
  margin-bottom: var(--dl-space-space-unit);
}
.book-text07 {
  margin-bottom: var(--dl-space-space-unit);
}
.book-contact-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.book-text09 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.book-text10 {
  margin-bottom: var(--dl-space-space-unit);
}
.book-subscribe-container {
  flex: 0 0 auto;
  width: 35%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.book-text18 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.book-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: #D9D9D9;
}
.book-max-width2 {
  flex-direction: row;
  justify-content: space-between;
}
.book-image1 {
  width: 200px;
  object-fit: cover;
}
.book-text19 {
  align-self: center;
}
@media(max-width: 1200px) {
  .book-primary {
    display: none;
  }
  .book-text01 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .book-text09 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .book-text18 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
}
@media(max-width: 991px) {
  .book-heading-container {
    max-width: 100%;
    margin-bottom: 42px;
  }
}
@media(max-width: 767px) {
  .book-text {
    font-size: 48px;
  }
  .book-top-part {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .book-links-container {
    width: 100%;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .book-subscribe-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .book-top-part {
    align-items: center;
    flex-direction: column;
  }
  .book-links-container {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .book-contact-container {
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .book-max-width2 {
    flex-direction: column;
  }
  .book-text19 {
    text-align: center;
  }
}
