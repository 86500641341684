.session--card-container {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: rgba(182, 71, 47, 0.1);
}
.session--card-image {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.session--card-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: var(--dl-radius-radius-radius4);
}
.session--card-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.session--card-text12 {
  font-size: 1.8em;
  font-weight: bold;
  cursor: pointer;
}
@media(max-width: 1200px) {
  .session--card-container1 {
    width: 240px;
  }
  .session--card-text {
    margin-bottom: 5px;
  }
  .session--card-ul {
    margin-top: 0px;
    list-style-type: disc;
  }
  .session--card-text12 {
    font-size: 2em;
    text-decoration: none;
  }
}
