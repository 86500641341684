.page-not-found-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.page-not-found-image {
  width: 200px;
  object-fit: cover;
}
.page-not-found-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.page-not-found-hero {
  position: relative;
  padding-top: 128px;
}
.page-not-found-max-width {
  margin-top: var(--dl-space-space-threeunits);
}
.page-not-found-heading-container {
  flex: 1;
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.page-not-found-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.page-not-found-text01 {
  color: var(--dl-color-scheme-black);
  font-size: 18px;
  text-align: center;
  line-height: 1.44;
  margin-bottom: var(--dl-space-space-twounits);
}
.page-not-found-top-part {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.page-not-found-links-container {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.page-not-found-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.page-not-found-text04 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.page-not-found-text05 {
  margin-bottom: var(--dl-space-space-unit);
}
.page-not-found-text06 {
  margin-bottom: var(--dl-space-space-unit);
}
.page-not-found-navigate-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.page-not-found-text08 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.page-not-found-text09 {
  margin-bottom: var(--dl-space-space-unit);
}
.page-not-found-text10 {
  margin-bottom: var(--dl-space-space-unit);
}
.page-not-found-contact-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.page-not-found-text12 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.page-not-found-text13 {
  margin-bottom: var(--dl-space-space-unit);
}
.page-not-found-subscribe-container {
  flex: 0 0 auto;
  width: 35%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.page-not-found-text21 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.page-not-found-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: #D9D9D9;
}
.page-not-found-max-width2 {
  flex-direction: row;
  justify-content: space-between;
}
.page-not-found-image1 {
  width: 200px;
  object-fit: cover;
}
.page-not-found-text22 {
  align-self: center;
}
@media(max-width: 1200px) {
  .page-not-found-text01 {
    color: var(--dl-color-scheme-black);
    font-size: 18px;
    line-height: 1.44;
  }
  .page-not-found-text04 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .page-not-found-text12 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .page-not-found-text21 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
}
@media(max-width: 991px) {
  .page-not-found-heading-container {
    max-width: 100%;
    margin-bottom: 42px;
  }
}
@media(max-width: 767px) {
  .page-not-found-text {
    font-size: 48px;
  }
  .page-not-found-top-part {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .page-not-found-links-container {
    width: 100%;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .page-not-found-subscribe-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .page-not-found-top-part {
    align-items: center;
    flex-direction: column;
  }
  .page-not-found-links-container {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .page-not-found-contact-container {
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .page-not-found-max-width2 {
    flex-direction: column;
  }
  .page-not-found-text22 {
    text-align: center;
  }
}
