.random-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.random-image {
  width: 200px;
  object-fit: cover;
}
.random-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.random-hero {
  position: relative;
  padding-top: 128px;
}
.random-max-width {
  margin-top: var(--dl-space-space-threeunits);
}
.random-heading-container {
  flex: 1;
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.random-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.random-text04 {
  color: var(--dl-color-scheme-black);
  font-size: 18px;
  text-align: center;
  line-height: 1.44;
  margin-bottom: var(--dl-space-space-twounits);
}
.random-primary {
  margin-bottom: var(--dl-space-space-threeunits);
}
.random-gallery {
  width: 100%;
  display: flex;
  grid-gap: 20px;
  align-items: stretch;
}
.random-container1 {
  width: 25%;
  align-self: stretch;
}
.random-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.random-container2 {
  width: 25%;
  display: flex;
  grid-gap: 20px;
  align-items: stretch;
  flex-direction: column;
}
.random-image2 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.random-image3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.random-container3 {
  width: 50%;
  display: flex;
  grid-gap: 20px;
  flex-direction: column;
}
.random-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.random-container4 {
  width: 100%;
  display: flex;
  grid-gap: 20px;
}
.random-image5 {
  flex: 1;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.random-image6 {
  flex: 1;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.random-process {
  background-color: var(--dl-color-scheme-lightblue);
}
.random-max-width1 {
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.random-text09 {
  color: var(--dl-color-scheme-white);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.random-text10 {
  color: var(--dl-color-scheme-white);
  margin-bottom: var(--dl-space-space-fourunits);
}
.random-step {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.random-text14 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.random-container5 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.random-text15 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.random-text16 {
  color: var(--dl-color-scheme-white80);
}
.random-step1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.random-text17 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.random-container6 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.random-text18 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.random-text19 {
  color: var(--dl-color-scheme-white80);
}
.random-step2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.random-text27 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.random-container7 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.random-text28 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.random-text29 {
  color: var(--dl-color-scheme-white80);
}
.random-max-width2 {
  flex-direction: column;
}
.random-text-container {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.random-text30 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.random-text31 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.random-text35 {
  color: var(--dl-color-scheme-black80);
  text-align: center;
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.random-tab-selector-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.random-text39 {
  margin-right: 30px;
}
.random-text40 {
  margin-right: 30px;
}
.random-text41 {
  margin-right: 30px;
}
.random-text42 {
  margin-right: 30px;
}
.random-text43 {
  margin-right: 30px;
}
.random-tab-selector-cards-container {
  width: 100%;
  display: grid;
  grid-row-gap: 40px;
  grid-column-gap: 20px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
}
.random-services {
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: var(--dl-color-scheme-lightbrown);
}
.random-max-width3 {
  flex-direction: column;
}
.random-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
}
.random-text-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.random-text45 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.random-controls {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
}
.random-cards-container {
  width: 100%;
  display: flex;
  grid-gap: 20px;
}
.random-max-width4 {
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.random-text47 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.random-text48 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.random-text50 {
  color: var(--dl-color-scheme-black80);
  text-align: center;
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.random-primary2 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.random-blog-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.random-banner {
  background-color: var(--dl-color-scheme-brown);
}
.random-max-width5 {
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.random-text54 {
  color: var(--dl-color-scheme-white);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.random-text55 {
  color: var(--dl-color-scheme-white);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.random-text56 {
  color: var(--dl-color-scheme-white);
  text-align: center;
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.random-primary3 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.random-top-part {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.random-links-container {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.random-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.random-text57 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.random-text58 {
  margin-bottom: var(--dl-space-space-unit);
}
.random-text59 {
  margin-bottom: var(--dl-space-space-unit);
}
.random-navigate-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.random-text61 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.random-text62 {
  margin-bottom: var(--dl-space-space-unit);
}
.random-text63 {
  margin-bottom: var(--dl-space-space-unit);
}
.random-contact-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.random-text65 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.random-text66 {
  margin-bottom: var(--dl-space-space-unit);
}
.random-subscribe-container {
  flex: 0 0 auto;
  width: 35%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.random-text74 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.random-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: #D9D9D9;
}
.random-max-width7 {
  flex-direction: row;
  justify-content: space-between;
}
.random-image7 {
  width: 100px;
  object-fit: cover;
}
.random-text75 {
  align-self: center;
}
.random-about {
  background-color: var(--dl-color-scheme-lightbrown);
}
.random-max-width8 {
  flex-direction: row;
  justify-content: space-between;
}
.random-text-container2 {
  flex: 1;
  width: 40%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.random-text76 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.random-text77 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.random-text81 {
  color: var(--dl-color-scheme-black80);
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.random-checklist {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.random-check-item {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.random-icon {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.random-text82 {
  font-style: normal;
  font-weight: 500;
}
.random-check-item1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.random-icon02 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.random-text83 {
  font-style: normal;
  font-weight: 500;
}
.random-check-item2 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.random-icon04 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.random-text84 {
  font-style: normal;
  font-weight: 500;
}
.random-check-item3 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.random-icon06 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.random-text85 {
  font-style: normal;
  font-weight: 500;
}
.random-check-item4 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.random-icon08 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.random-text86 {
  font-style: normal;
  font-weight: 500;
}
.random-check-item5 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.random-icon10 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.random-text87 {
  font-style: normal;
  font-weight: 500;
}
.random-image8 {
  flex: 1;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
@media(max-width: 1200px) {
  .random-text04 {
    color: var(--dl-color-scheme-black);
  }
  .random-primary {
    display: none;
  }
  .random-image4 {
    height: 521px;
    align-self: stretch;
    margin-bottom: 0px;
  }
  .random-image5 {
    height: var(--dl-size-size-xxlarge);
  }
  .random-image6 {
    height: var(--dl-size-size-xxlarge);
  }
  .random-process {
    background-color: #007fc2;
  }
  .random-text09 {
    color: var(--dl-color-scheme-white);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .random-text15 {
    color: var(--dl-color-scheme-white);
    font-size: 24px;
    line-height: 31px;
  }
  .random-text16 {
    color: var(--dl-color-scheme-white80);
  }
  .random-text18 {
    color: var(--dl-color-scheme-white);
    font-size: 24px;
    line-height: 31px;
  }
  .random-text19 {
    color: var(--dl-color-scheme-white80);
  }
  .random-text21 {
    font-weight: 700;
  }
  .random-text28 {
    color: var(--dl-color-scheme-white);
  }
  .random-text29 {
    color: var(--dl-color-scheme-white80);
  }
  .random-portofolio {
    display: none;
  }
  .random-text30 {
    color: var(--dl-color-scheme-brown);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .random-text35 {
    color: var(--dl-color-scheme-black80);
    line-height: 26px;
  }
  .random-text45 {
    color: var(--dl-color-scheme-brown);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .random-blog {
    display: none;
  }
  .random-banner {
    background-color: #e1f2f4;
  }
  .random-text54 {
    color: var(--dl-color-scheme-lightblue);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .random-text55 {
    color: var(--dl-color-scheme-darkblue);
  }
  .random-text56 {
    color: var(--dl-color-scheme-lightblue);
    line-height: 26px;
  }
  .random-primary3 {
    color: var(--dl-color-scheme-white);
    background-color: rgb(10, 160, 228);
  }
  .random-text57 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .random-text65 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .random-text74 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .random-image7 {
    width: 200px;
  }
  .random-about {
    background-color: var(--dl-color-scheme-white80);
  }
}
@media(max-width: 991px) {
  .random-heading-container {
    max-width: 100%;
    margin-bottom: 42px;
  }
  .random-gallery {
    flex-direction: column;
  }
  .random-container1 {
    width: 100%;
  }
  .random-container2 {
    width: 100%;
    flex-direction: row;
  }
  .random-container3 {
    width: 100%;
    align-items: stretch;
  }
  .random-tab-selector-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .random-blog-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .random-max-width8 {
    flex-direction: column;
  }
  .random-text-container2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .random-image-container {
    width: 100%;
  }
  .random-image8 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .random-text {
    font-size: 48px;
  }
  .random-container2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .random-image2 {
    height: 100%;
  }
  .random-container4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .random-image5 {
    width: 100%;
  }
  .random-image6 {
    width: 100%;
  }
  .random-text14 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .random-text17 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .random-text27 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .random-tab-selector-cards-container {
    grid-template-columns: 1fr;
  }
  .random-top-part {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .random-links-container {
    width: 100%;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .random-subscribe-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .random-text14 {
    margin-right: var(--dl-space-space-twounits);
  }
  .random-text17 {
    margin-right: var(--dl-space-space-twounits);
  }
  .random-text27 {
    margin-right: var(--dl-space-space-twounits);
  }
  .random-top-part {
    align-items: center;
    flex-direction: column;
  }
  .random-links-container {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .random-contact-container {
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .random-max-width7 {
    flex-direction: column;
  }
  .random-image7 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .random-text75 {
    text-align: center;
  }
}
