.faq-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.faq-image {
  width: 200px;
  object-fit: cover;
}
.faq-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-hero {
  position: relative;
  padding-top: 128px;
}
.faq-max-width {
  margin-top: var(--dl-space-space-threeunits);
}
.faq-heading-container {
  flex: 1;
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.faq-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.faq-text001 {
  color: var(--dl-color-scheme-black);
  font-size: 18px;
  text-align: center;
  line-height: 1.44;
  margin-bottom: var(--dl-space-space-twounits);
}
.faq-services {
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: var(--dl-color-scheme-lightbrown);
}
.faq-max-width1 {
  flex-direction: column;
}
.faq-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
}
.faq-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-text004 {
  font-size: 16px;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.01em;
  text-decoration: none;
}
.faq-controls {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
}
.faq-top-part {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.faq-links-container {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.faq-text104 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.faq-text105 {
  margin-bottom: var(--dl-space-space-unit);
}
.faq-text106 {
  margin-bottom: var(--dl-space-space-unit);
}
.faq-navigate-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.faq-text108 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.faq-text109 {
  margin-bottom: var(--dl-space-space-unit);
}
.faq-text110 {
  margin-bottom: var(--dl-space-space-unit);
}
.faq-contact-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.faq-text112 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.faq-text113 {
  margin-bottom: var(--dl-space-space-unit);
}
.faq-subscribe-container {
  flex: 0 0 auto;
  width: 35%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-text121 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.faq-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: #D9D9D9;
}
.faq-max-width3 {
  flex-direction: row;
  justify-content: space-between;
}
.faq-image1 {
  width: 200px;
  object-fit: cover;
}
.faq-text122 {
  align-self: center;
}
@media(max-width: 1600px) {
  .faq-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .faq-text001 {
    color: var(--dl-color-scheme-black);
    font-size: 18px;
    line-height: 1.44;
    margin-bottom: 0px;
  }
  .faq-services {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .faq-heading-container1 {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .faq-text-container {
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .faq-text004 {
    width: 100%;
    font-size: 20px;
    align-self: center;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.01em;
    text-decoration: none;
  }
  .faq-text005 {
    font-weight: 700;
  }
  .faq-text010 {
    font-weight: 700;
  }
  .faq-text013 {
    font-weight: 700;
  }
  .faq-text015 {
    font-weight: 700;
  }
  .faq-text017 {
    font-weight: 700;
  }
  .faq-text019 {
    font-weight: 700;
  }
  .faq-text023 {
    font-weight: 700;
  }
  .faq-text028 {
    font-weight: 700;
  }
  .faq-text033 {
    font-weight: 700;
  }
  .faq-text040 {
    font-weight: 700;
  }
  .faq-text045 {
    font-weight: 700;
  }
  .faq-text050 {
    font-weight: 700;
  }
  .faq-text054 {
    font-style: italic;
  }
  .faq-text055 {
    font-style: italic;
  }
  .faq-text056 {
    font-style: italic;
  }
  .faq-text059 {
    font-weight: 700;
  }
  .faq-text062 {
    font-weight: 700;
  }
  .faq-text070 {
    font-weight: 700;
  }
  .faq-text075 {
    font-weight: 700;
  }
  .faq-text084 {
    font-weight: 700;
  }
  .faq-text089 {
    font-weight: 700;
  }
  .faq-text094 {
    font-weight: 700;
  }
  .faq-text101 {
    font-weight: 700;
  }
}
@media(max-width: 1200px) {
  .faq-hero {
    padding-top: 20px;
  }
  .faq-text001 {
    color: var(--dl-color-scheme-black);
  }
  .faq-text104 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .faq-text112 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .faq-text121 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
}
@media(max-width: 991px) {
  .faq-heading-container {
    max-width: 100%;
    margin-bottom: 42px;
  }
}
@media(max-width: 767px) {
  .faq-text {
    font-size: 48px;
  }
  .faq-top-part {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .faq-links-container {
    width: 100%;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .faq-subscribe-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .faq-top-part {
    align-items: center;
    flex-direction: column;
  }
  .faq-links-container {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .faq-contact-container {
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .faq-max-width3 {
    flex-direction: column;
  }
  .faq-text122 {
    text-align: center;
  }
}
