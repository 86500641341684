.home-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.home-image {
  width: 200px;
  object-fit: cover;
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-hero {
  position: relative;
  padding-top: 128px;
}
.home-max-width {
  margin-top: var(--dl-space-space-threeunits);
}
.home-heading-container {
  flex: 1;
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text04 {
  color: var(--dl-color-scheme-black);
  font-size: 18px;
  text-align: center;
  line-height: 1.44;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-primary {
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-gallery {
  width: 100%;
  display: flex;
  grid-gap: 20px;
  align-items: stretch;
  max-height: 600px;
}
.home-image {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-container1 {
  width: 25%;
  display: flex;
  grid-gap: 20px;
  align-items: stretch;
  flex-direction: column;
}
.home-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-container2 {
  width: 25%;
  display: flex;
  grid-gap: 20px;
  align-items: stretch;
  flex-direction: column;
}
.home-image2 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  height: 50%;
}
.home-image3 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  height: 50%;
}
.home-container3 {
  width: 50%;
  display: flex;
  grid-gap: 20px;
  flex-direction: column;
  height: 70%;
}
.home-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-container4 {
  width: 100%;
  display: flex;
  grid-gap: 20px;
  height: 30%;
}
.home-image5 {
  flex: 1;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-image6 {
  flex: 1;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-process {
  background-color: var(--dl-color-scheme-lightblue);
}
.home-max-width1 {
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.home-text09 {
  color: var(--dl-color-scheme-white);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text10 {
  color: var(--dl-color-scheme-white);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-step {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-text14 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.home-container5 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text15 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text16 {
  color: var(--dl-color-scheme-white80);
}
.home-step1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-text17 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.home-container6 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text18 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text19 {
  color: var(--dl-color-scheme-white80);
}
.home-step2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-text27 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.home-container7 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text28 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text29 {
  color: var(--dl-color-scheme-white80);
}
.home-services {
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: var(--dl-color-scheme-lightbrown);
}
.home-max-width2 {
  flex-direction: column;
}
.home-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text30 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-controls {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
}
.home-cards-container {
  width: 100%;
  display: flex;
  grid-gap: 20px;
}
.home-top-part {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.home-links-container {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text32 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text33 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text34 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-navigate-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text36 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text37 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text38 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-contact-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text40 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text41 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-subscribe-container {
  flex: 0 0 auto;
  width: 35%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text49 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: #D9D9D9;
}
.home-max-width4 {
  flex-direction: row;
  justify-content: space-between;
}
.home-image7 {
  width: 200px;
  object-fit: cover;
}
.home-text50 {
  align-self: center;
}
@media(max-width: 1200px) {
  .home-text04 {
    color: var(--dl-color-scheme-black);
  }
  .home-image4 {
    height: 521px;
    align-self: stretch;
    margin-bottom: 0px;
  }
  .home-image5 {
    height: var(--dl-size-size-xxlarge);
  }
  .home-image6 {
    height: var(--dl-size-size-xxlarge);
  }
  .home-process {
    background-color: #007fc2;
  }
  .home-text09 {
    color: var(--dl-color-scheme-white);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .home-text15 {
    color: var(--dl-color-scheme-white);
    font-size: 24px;
    line-height: 31px;
  }
  .home-text16 {
    color: var(--dl-color-scheme-white80);
  }
  .home-text18 {
    color: var(--dl-color-scheme-white);
    font-size: 24px;
    line-height: 31px;
  }
  .home-text19 {
    color: var(--dl-color-scheme-white80);
  }
  .home-text21 {
    font-weight: 700;
  }
  .home-text28 {
    color: var(--dl-color-scheme-white);
  }
  .home-text29 {
    color: var(--dl-color-scheme-white80);
  }
  .home-text30 {
    color: var(--dl-color-scheme-brown);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .home-text32 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .home-text40 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
  .home-text49 {
    color: var(--dl-color-scheme-brown);
    font-weight: 700;
  }
}
@media(max-width: 991px) {
  .home-heading-container {
    max-width: 100%;
    margin-bottom: 42px;
  }
  .home-gallery {
    flex-direction: column;
  }
  .home-container1 {
    width: 100%;
  }
  .home-container2 {
    width: 100%;
    flex-direction: row;
  }
  .home-container3 {
    width: 100%;
    align-items: stretch;
  }
}
@media(max-width: 767px) {
  .home-text {
    font-size: 48px;
  }
  .home-container2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .home-image2 {
    height: 100%;
  }
  .home-container4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .home-image5 {
    width: 100%;
  }
  .home-image6 {
    width: 100%;
  }
  .home-text14 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-text17 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-text27 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-top-part {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-links-container {
    width: 100%;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .home-subscribe-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-text14 {
    margin-right: var(--dl-space-space-twounits);
  }
  .home-text17 {
    margin-right: var(--dl-space-space-twounits);
  }
  .home-text27 {
    margin-right: var(--dl-space-space-twounits);
  }
  .home-top-part {
    align-items: center;
    flex-direction: column;
  }
  .home-links-container {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-contact-container {
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .home-max-width4 {
    flex-direction: column;
  }
  .home-text50 {
    text-align: center;
  }
}
